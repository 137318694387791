<template>
  <div>
    <!-- Render NavBar only on certain routes -->
    <NavBar v-if="$route.name !== 'nfc'" />

    <!-- Use <router-view> to display the routed components -->
    <router-view />

    <!-- Render other components only on certain routes -->
    <FloatingIcon v-if="$route.name !== 'nfc'" />

    <!-- Render the Home, About, ServicesView, and Contactus only on the home route -->
    <div v-if="$route.name === 'home'">
      <!-- <Home /> -->
      <About />
      <ServicesView />
      <Contactus />
      <FooterView v-if="$route.name !== 'nfc'" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/nav.vue";
import FloatingIcon from "@/components/floating_whatsapp.vue";
import Home from "@/views/HomeView.vue";
import About from "@/views/AboutView.vue";
import Contactus from "@/views/contact_us.vue";
import ServicesView from "@/views/ServicesView.vue";
import FooterView from "@/views/footerView.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    // eslint-disable-next-line vue/no-unused-components
    Home,
    About,
    ServicesView,
    Contactus,
    FooterView,
    FloatingIcon,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  margin-right: 5px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      margin-right: 5px;

      color: #42b983;
    }
  }
}
</style>
