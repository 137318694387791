<template>
  <footer
    class="footer"
    role="contentinfo"
    itemscope
    itemtype="http://schema.org/WPFooter"
  >
    <div class="footer-content">
      <div
        class="footer-left"
        itemscope
        itemtype="http://schema.org/Organization"
      >
        <img
          src="../assets/logo-swiss.png"
          alt="Logo"
          itemprop="logo"
          class="u-logo logo"
        />
        <p class="footer-name">Swiss Lake Capital © 2024</p>
      </div>

      <div class="footer-center">
        <div class="contact-info">
          <i class="fa fa-map-marker" aria-hidden="true"></i>
          <span>
            <strong>Address:</strong>
            <span itemprop="streetAddress" class="p-street-address"
              >Ajman Free Zone, Office - C1 - 1F - SF0410</span
            >, <span itemprop="addressLocality" class="p-locality">Ajman</span>,
            <span itemprop="addressRegion" class="p-region">UAE</span>
          </span>
        </div>

        <div class="contact-info">
          <i class="fa fa-phone" aria-hidden="true"></i>
          <span itemprop="telephone" class="p-tel">Phone: +971565987984</span>
        </div>

        <div class="contact-info">
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <span>
            <a
              href="mailto:zeyadabdelaziz@swisslakecapital.com"
              itemprop="email"
              class="u-email"
              >Email: zeyadabdelaziz@swisslakecapital.com</a
            >
          </span>
        </div>
        <div class="contact-info">
          <img
            src="https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png"
            alt="LinkedIn"
            style="width: 20px; height: 20px; margin-right: 10px"
          />
          <span>
            <a
              href="https://www.linkedin.com/in/zeyad-abdelaziz-a33113194/"
              target="_blank"
              rel="noopener noreferrer"
              class="u-linkedin"
              >LinkedIn: Zeyad Abdelaziz</a
            >
          </span>
        </div>
      </div>

      <div class="footer-right">
        <div class="footer-socials">
          <a
            href="#"
            rel="me"
            aria-label="Facebook"
            itemprop="sameAs"
            class="u-url"
            ><i class="fa-brands fa-facebook"></i
          ></a>
          <a
            href="#"
            rel="me"
            aria-label="Twitter"
            itemprop="sameAs"
            class="u-url"
            ><i class="fa-brands fa-x-twitter"></i
          ></a>
          <a
            href="#"
            rel="me"
            aria-label="LinkedIn"
            itemprop="sameAs"
            class="u-url"
            ><i class="fa-brands fa-linkedin"></i
          ></a>
          <a
            href="#"
            rel="me"
            aria-label="GitHub"
            itemprop="sameAs"
            class="u-url"
            ><i class="fa-brands fa-github"></i
          ></a>
        </div>
        <div class="footer-partner">
          <img
            src="../assets/greenmarmot_white.jpeg"
            alt="Green Marmot"
            class="partner-logo"
          />
          <p>Green Marmot navigation</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background: #ffffff;
  width: 100%;
  text-align: center;
  font: 16px sans-serif;
  padding: 30px 20px;
  color: #333333;
  border-top: 1px solid #e0e0e0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left {
  margin-bottom: 20px;
}

.footer .footer-name {
  color: #666666;
  font-size: 14px;
  margin: 8px 0;
}

/* Footer center */
.footer-center {
  margin-bottom: 20px;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  color: #555555;
}

.contact-info i {
  margin-right: 8px;
}

.footer-socials a {
  display: inline-block;
  font-size: 24px;
  color: #666666;
  margin-right: 15px;
  transition: color 0.3s;
}

.footer-socials a:hover {
  color: #0073e6;
}

.footer-partner {
  text-align: center;
}

.footer .u-email {
  color: #0073e6;
  text-decoration: none;
}

.footer .u-email:hover {
  text-decoration: underline;
}

.footer .partner-logo {
  max-width: 120px;
  margin-top: 8px;
}

.footer .logo {
  max-width: 180px;
  height: auto;
}

/* Media query for larger screens */
@media (min-width: 800px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    flex: 1;
    text-align: left;
  }

  .footer-center {
    text-align: center;
  }
}
</style>
