<template>
  <div class="business-card-container">
    <div class="business-card">
      <div class="profile-image">
        <img src="../assets/logo-swiss.png" alt="Profile Image" />
      </div>
      <div class="info">
        <h1>Zeyad Abdelaziz</h1>
        <p class="title">CEO Swiss lake capital</p>
        <p class="description">
          Swiss Lake is a pool of capital belonging to individual investors
          seeking halal, outstanding quarterly profits through open-ended
          agreements.
        </p>
        <div class="links">
          <a
            href="https://www.linkedin.com/in/zeyad-abdelaziz-a33113194?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            >LinkedIn</a
          >
          <a href="mailto:zeyadabdelaziz@swisslakecapital.com">Email</a>
          <a href="tel:+971565987984">Call</a>
          <!-- Phone link -->

          <a href="https://swisslakecapital.com/" target="_blank">Website</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessCard",
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: #f0f2f5;
  height: 100vh; /* Ensure body takes full height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height of the viewport */
}

.business-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 40px; /* Padding for more space inside the card */
  width: 400px;
  height: 500px; /* Fixed height for the card */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.business-card:hover {
  transform: translateY(-5px);
}

.profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px 0;
  border: 3px solid #0073b1;
}

.info {
  margin-bottom: 20px;
}

h1 {
  font-size: 26px;
  font-weight: bold;
  color: #333;
}

.title {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.links {
  display: flex; /* Use flexbox to align items in a row */
  justify-content: space-around; /* Space items evenly */
  width: 100%; /* Take full width of the container */
  padding: 0 10px; /* Add some horizontal padding */
}

.links a {
  padding: 10px 20px; /* Padding for each link */
  font-size: 16px; /* Font size for the links */
  background-color: #0073b1; /* Background color for the links */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Round the corners */
  transition: background-color 0.3s; /* Transition for hover effect */
  flex: 1; /* Allow links to grow equally */
  margin: 0 5px; /* Space between links */
}

.links a:hover {
  background-color: #005582; /* Darker shade on hover */
}
</style>
